@font-face {
  font-family: 'DMSans';
  src: url('/fonts/DMSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'DMSans';
  src: url('/fonts/DMSans-Medium.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'DMSans';
  src: url('/fonts/DMSans-Bold.ttf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'DMSans';
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

a {
  text-decoration: none;
  color: black;
}

img,
picture {
  max-width: 100%;
  display: block;
}

img {
  object-fit: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
